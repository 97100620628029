import { Pipe, PipeTransform } from '@angular/core';
import { Link } from './link';

@Pipe({
    name: 'bottom',
    standalone: false
})
export class BottomPipe implements PipeTransform {

  transform(value: Array<Link>, isBottomMenu?: boolean): any {
    return value.filter(l => l.inBottomMenu == isBottomMenu);
  }

}
